.footer0-wrapper {
  background-color: @template-bg-color;
  height: 80px;
  overflow: hidden;
  .footer0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 24px;
    line-height: 80px;
    text-align: center;
    color: @template-footer-text-color;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .footer0-wrapper {
    .footer0 {
      font-size: 12px;
      &.home-page {
        padding: 0;
      }
      >div {
        width: 90%;
        margin: auto;
      }
    }
  }
}
