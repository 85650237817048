@content3: content3;
.@{content3}-wrapper {
  min-height: 764px;
  .@{content3} {
    height: 100%;
    overflow: hidden;
    & .title-content {
      text-align: center;
    }
    &-block-wrapper {
      display: flex;
      position: relative;
      .@{content3}-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 48px 24px;
        .@{content3}-icon {
          transform: none !important;
          margin-bottom: 12px;
          width: 40%;
        }
        .@{content3}-text {
          text-align: center;
        }
      }
    }
  }
}

.content3-title {
  margin-bottom: 12px;
  transform: none !important;
}

@media screen and (max-width: 767px) {
  .@{content3}-wrapper {
    min-height: 1080px;
    .@{content3} {
      &-block-wrapper {
        margin: 20px auto;
        height: auto;
        .@{content3}-block {
          .@{content3}-title {
            font-size: 20px;
          }
          &.queue-anim-leaving {
            position: relative !important;
          }
        }
      }
    }
  }
}
